<template>
  <div id="addedit-main">
    <div class="addedit-title" v-if="IDD">{{Title1}}</div>
    <div class="addedit-title" v-else>{{Title2}}</div>
    <div class="addedit-bor"></div>
    <div class="addedit-cont">
      <n-form
        :model="model"
        ref="formRef"
        :rules="rules"
        label-placement="left"
        :label-width="120"
      >
        <n-form-item
          label="选择角色 : "
          path="selectValue"
          :show-require-mark="true"
        >
          <n-select
            placeholder="请选择角色"
            :options="selectData"
            v-model:value="model.selectValue"
          />
        </n-form-item>

        <n-form-item path="name" label="账号 : " :show-require-mark="true">
          <n-input v-model:value="model.name" @keydown.enter.prevent :disabled="id" maxlength="20" show-count />
        </n-form-item>
        <n-form-item path="password" label="密码 : " :show-require-mark="true">
          <n-input
            v-model:value="model.password"
            @input="handlePasswordInput"
            @keydown.enter.prevent
            maxlength="20" show-count
          />
        </n-form-item>
        <n-form-item
        :show-require-mark="true"
          first
          path="reenteredPassword"
          label="重复密码 : "
          ref="rPasswordFormItemRef"
        >
          <n-input
            v-model:value="model.reenteredPassword"
            @keydown.enter.prevent
            maxlength="20" show-count
          />
        </n-form-item>
        <n-form-item
          first
          path="remark"
          label="备注 : "
          ref="rPasswordFormItemRef"
        >
          <n-input v-model:value="model.remark" type="text" maxlength="50" show-count />
        </n-form-item>
        <n-row :gutter="[0, 24]">
          <n-col :span="24">
            <div class="addedit-btns">
              <n-button type="tertiary" >
                <a href="/admin/assignment/index/list">返回</a>
                </n-button>
              <n-button
                @click="handleValidateButtonClick"
                round
              >
                确定</n-button
              >
            </div>
          </n-col>
        </n-row>
      </n-form>
      <!-- <PageLoading :loading="loading" /> -->
      <pre>
      <!-- {{ JSON.stringify(model, 0, 2) }} -->
      </pre>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useMessage } from "naive-ui";
import { resStatusEnum } from "@/enumerators/http.js";
import {
  GETAssignmentDelete,
  AssignmentUpdate,
  AssignmentCreate,
  SystemPowerRoles
} from "@/api/admin-home.js"; // 详情 编辑 新增 角色列表
import { useRoute } from "vue-router";
import { toRaw } from "@vue/reactivity";

const { SUCCESS } = resStatusEnum;
const loading = ref(false);

export default defineComponent({
  setup() {
    const router = useRoute();
    let id = router.query.id;
    const formRef = ref(null);
    const rPasswordFormItemRef = ref(null);
    const message = useMessage();
    const selectData = ref([])
    const model = ref({
      selectValue: null,
      name: null,
      password: null,
      reenteredPassword: null,
      remark: null,
      id:null
    });

    function validatePasswordStartWith(rule, value) {
      return (
        model.value.password &&
        model.value.password.startsWith(value) &&
        model.value.password.length >= value.length
      );
    }
    function validatePasswordSame(rule, value) {
      return value === model.value.password;
    }

    // 数据初始化
    function InitData() {
      loading.value = true;
      // 请求列表数据
      GETAssignmentDelete({ "AdminModel[id]": id })
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            
            selectData.value = res.data.role_list;
            model.value.remark = res.data.info.remark
            model.value.name = res.data.info.username
            model.value.selectValue = res.data.info.role
            model.value.id = res.data.info.id
          }
        })
        .catch((reject) => {});
    }

    // 新增是获取角色列表
    function RoleList(params) {
      loading.value = true;
      SystemPowerRoles({"page_size":999})
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            selectData.value = res.data.list
          }
        })
        .catch((reject) => {});
    }
    // 是否是新增
    if(id){
      InitData();
    }else{
      RoleList();
    }
    
    return {
      id,
      IDD:id,
      formRef,
      rPasswordFormItemRef,
      model,
      selectData,
      Title1:"编辑账号",
      Title2:"新增账号",
      rules: {
        name: [
          {
            required: false,
            validator(rule, value) {
              if (!value) {
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
        password: [
          {
            required: false,
            message: "请输入密码",
          },
        ],
        reenteredPassword: [
          {
            required: false,
            message: "请再次输入密码",
            trigger: ["input", "blur"],
          },
          {
            validator: validatePasswordStartWith,
            message: "两次密码输入不一致",
            trigger: "input",
          },
          {
            validator: validatePasswordSame,
            message: "两次密码输入不一致",
            trigger: ["blur", "password-input"],
          },
        ],
      },
      handlePasswordInput() {
        if (model.value.reenteredPassword) {
          rPasswordFormItemRef.value.validate({ trigger: "password-input" });
        }
      },

      handleValidateButtonClick(e) {
        formRef.value.validate((errors) => {
          if (!errors) {
            loading.value = true;
            
            let data = {
              "AdminModel[id]":model.value.id,            // 账号id
              "AdminModel[role]":model.value.selectValue, // 角色id
              "AdminModel[password]":model.value.password,// 密码
              "AdminModel[re_password]":model.value.reenteredPassword, // 确认密码
              "AdminModel[remark]":model.value.remark,    // 备注
              "AdminModel[username]":model.value.name
            }

            // 编辑
            if(id){
              AssignmentUpdate(data).then((res) => {
              if (res.code === SUCCESS) {
                loading.value = false;
                message.success(res.msg);
              }
            })
            }else{
            // 新增
            AssignmentCreate(data).then((res) => {
              if (res.code === SUCCESS) {
                loading.value = false;
                message.success(res.msg);
                window.location.href = '/admin/assignment/index/list'
              }
            })
            }
            
          } else {
            message.error("验证失败");
          }
        });
      }
    };
  },
});
</script>

<style scoped>
#addedit-main {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}
#addedit-main .addedit-title {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}
.addedit-bor {
  height: 1px;
  border-bottom: 1px solid #d7d7d7;
  position: absolute;
  left: 0;
  right: 0;
  top: 65px;
}
.n-form-item-feedback-wrapper {
  padding-left: 120px;
}
.addedit-cont {
  width: 400px;
  padding-top: 45px;
}
.addedit-btns {
  display: flex;
  justify-content: flex-end;
  padding-top: 60px;
}
.addedit-btns button {
  border-radius: 2px !important;
  width: 120px;
}
.addedit-btns .n-button--tertiary-type.n-button--medium-type {
  border: 1px solid #ccc;
  margin-right: 30px;
}
.n-button__content a{
  color: #000;
  text-decoration: none;
}
</style>